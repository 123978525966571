.button-container button {
  cursor: pointer;
  background: transparent;
  border: 1px solid black;
  padding: 1em;
  font-family: "Josefin Sans", sans-serif;
  margin: 1em 0;
}
.button-container button:focus {
  outline: none;
}

