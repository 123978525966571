.home-page-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.home-page-container .visual-area {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3em;
}
.home-page-container .node-creator {
  padding: 1em;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 5px solid #8d99ae;
  background: whitesmoke;
  height: 100%;
  width: 230px;
  font-size: 1.3em;
}
.home-page-container .node-creator .write-area {
  overflow: auto;
}

