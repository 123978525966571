.node-container
  z-index: 1
  color: whitesmoke
  border-radius: 50%
  width: 50px
  height: 50px
  background: orange
  //border: 3px solid black
  animation-name: pop-up
  animation-duration: .2s
  text-align: center
  transition: all .2s
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  p
    animation-duration: .27s
    animation-name: pop-up
@keyframes pop-up
  0%
    transform: scale(0)
  100%
    transform: scale(1)