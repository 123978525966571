.input-field-container input {
  background: transparent;
  border: 1px solid black;
  padding: 1em;
  font-family: "Josefin Sans", sans-serif;
}
.input-field-container input:focus {
  outline: none;
}

