$FONT: 'Josefin Sans', sans-serif
.button-container
  button
    cursor: pointer
    background: transparent
    border: 1px solid black
    padding: 1em
    font-family: $FONT
    margin: 1em 0
    &:focus
      outline: none