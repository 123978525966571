.navigation-container {
  width: fit-content;
  padding: 1em;
  background: whitesmoke;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
}
.navigation-container .logo-img {
  width: 100px;
}
.navigation-container .how-work {
  cursor: pointer;
}
.navigation-container .traversals-menu-container {
  position: relative;
  z-index: 2;
}
.navigation-container .traversals-menu-container .traversals-menu {
  animation-name: slide-right;
  animation-duration: 0.4s;
  position: absolute;
  top: -100%;
  left: 0;
  animation-fill-mode: forwards;
  background: whitesmoke;
  padding: 1em;
  border-radius: 0 10px 10px 0;
  z-index: 1;
}
@keyframes slide-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(110% + 1em));
  }
}

